import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContentSection from '../../ContentSection';

const Facts = () => {
  const { t } = useTranslation('SerpChecker');
  const title = t('Facts.title');
  const facts = [
    { title: t('Facts.fact1.title'), subtitle: t('Facts.fact1.subtitle') },
    { title: t('Facts.fact2.title'), subtitle: t('Facts.fact2.subtitle') },
    { title: t('Facts.fact3.title'), subtitle: t('Facts.fact3.subtitle') },
    { title: t('Facts.fact4.title'), subtitle: t('Facts.fact4.subtitle') },
  ];

  return (
    <ContentSection title={title} background={2}>
      <Row className="justify-content-center">
        {facts.map((fact, index) => (
          <Col
            lg="3"
            md="3"
            sm="6"
            className="mb-9 mb-lg-0"
            key={`spyserp-fact-${index}`}
          >
            <div className="single-fact text-center px-xl-6">
              <div className="title h3 mb-7 gr-text-3">{fact.title}</div>
              <p className="gr-text-8 mb-0">{fact.subtitle}</p>
            </div>
          </Col>
        ))}
      </Row>
    </ContentSection>
  );
};

export default Facts;
