import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import Hero from '../../Hero';
import SerpCheckerWidget from '../../SpySerpApp/SerpCheckerWidget';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';

const imagesQuery = graphql`
  query ImagesQuery {
    seImage: file(
      sourceInstanceName: { eq: "serpChecker" }
      name: { eq: "serp-checker" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          width: 621
          quality: 100
          placeholder: BLURRED
          layout: CONSTRAINED
        )
      }
    }

    seImageMobile: file(
      sourceInstanceName: { eq: "serpChecker" }
      name: { eq: "serp-checker-mobile" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          width: 363
          quality: 100
          placeholder: BLURRED
          layout: CONSTRAINED
        )
      }
    }
  }
`;

const HeroSerpChecker = () => {
  const [isTouched, setIsTouched] = useState(false);
  const { t } = useTranslation('SerpChecker');
  const { seImage, seImageMobile } = useStaticQuery(imagesQuery);
  const title = t('HeroSerpChecker.title');
  const description = t('HeroSerpChecker.description');

  const images = withArtDirection(getImage(seImage), [
    {
      media: '(max-width: 576px)',
      image: getImage(seImageMobile),
    },
  ]);

  return (
    <Hero title={title} description={description} hideSignupButton>
      {isTouched && <SerpCheckerWidget />}

      {!isTouched && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            className="hero-img-placeholder"
            onClick={() => setIsTouched(true)}
          >
            <GatsbyImage alt="SERP Checker" image={images} loading="eager" />
          </div>
        </div>
      )}
    </Hero>
  );
};

export default HeroSerpChecker;
