import React from 'react';
import HeroSerpChecker from '../components/pages/SerpChecker/HeroSerpChecker';
import Cta from '../components/pages/SerpChecker/CTA';
import Cta2 from '../components/pages/SerpChecker/CTA2';
import Facts from '../components/pages/SerpChecker/Facts';
import Testimonials from '../components/pages/SerpChecker/Testimonials';
import { useTranslation } from 'react-i18next';
import MetaTags from '../components/Seo/MetaTags';
import SeoChecker from '../components/pages/SerpChecker/SeoChecker';
import LocationsDifference from '../components/pages/SerpChecker/LocationsDifference';
import CompareResults from '../components/pages/SerpChecker/CompareResults';
import CompareUserLanguageResults from '../components/pages/SerpChecker/CompareUserLanguageResults';
import KeywordsDifference from '../components/pages/SerpChecker/KeywordsDifference';
import CompareManyResults from '../components/pages/SerpChecker/CompareManyResults';
import ExportResults from '../components/pages/SerpChecker/ExportResults';
import StructuredService from '../components/Seo/StructuredService';
import StructuredAggregateRating from '../components/Seo/StructuredAggregateRating';

const SerpCheckerPage = () => {
  const { t } = useTranslation('SerpChecker');

  return (
    <>
      <MetaTags title={t('seo.title')} description={t('seo.description')} />

      <StructuredService name="serp checker" />
      <StructuredAggregateRating
        name="SpySERP serp checker tool"
        description="SERP Checker gives SEO and digital marketing professionals a thorough analysis of SERP performance to boost SEO strategy. Get real-time SERP results with SpySERP."
        makesOffer="serp checker"
        knowsAbout="serp checker tool"
        ratingValue={5}
        bestRating={5}
        ratingCount={24}
      />

      <HeroSerpChecker />
      <SeoChecker />
      <LocationsDifference />
      <CompareResults />
      <CompareUserLanguageResults />
      <KeywordsDifference />
      <CompareManyResults />
      <ExportResults />
      <Facts />
      <Cta />
      <Testimonials />
      <Cta2 />
    </>
  );
};

export default SerpCheckerPage;
