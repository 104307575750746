import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContentSection from '../../ContentSection';
import GetStartedButton from '../../GetStartedButton';

const CTA = () => {
  const { t } = useTranslation('SerpChecker');

  return (
    <ContentSection>
      <Row className="align-items-center ">
        <Col lg="7">
          <div className="cta-text section-title">
            <h2 className="title gr-text-5 mb-7">{t('CTA.title')}</h2>
            <p className="gr-text-8 mb-8 mb-lg-0">{t('CTA.subtitle1')}</p>
            <p className="gr-text-8 font-weight-bold">{t('CTA.subtitle2')}</p>
          </div>
        </Col>

        <Col lg="5" xl="5" className="text-right">
          <GetStartedButton />
        </Col>
      </Row>
    </ContentSection>
  );
};

export default CTA;
