import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import ContentSection from '../../ContentSection';
import GetStartedButton from '../../GetStartedButton';

function CompareResults() {
  const { t } = useTranslation('SerpChecker');
  const title = t('CompareResults.title');
  const description = t('CompareResults.description');

  return (
    <ContentSection background={6}>
      <Row
        className="align-items-center"
        itemScope={true}
        itemType="https://schema.org/ImageObject"
      >
        <Col lg="7">
          <StaticImage
            src="../../../assets/image/serp-checker/serp-checker3_web.png"
            width={635}
            sizes="(min-width: 635px) 635px, (min-width: 360px) 330px, 100vw"
            breakpoints={[330, 635, 990, 1270]}
            alt={title}
            placeholder="blurred"
            className="shadow-1"
            itemProp="contentUrl"
          />
        </Col>

        <Col lg="5" data-aos="fade-left" data-aos-duration="700">
          <h2 className="title gr-text-4 mb-7 mt-7" itemProp="name">
            {title}
          </h2>

          <p className="gr-text-8" itemProp="description">
            {description}
          </p>
          <GetStartedButton />
        </Col>
      </Row>
    </ContentSection>
  );
}

export default CompareResults;
