import React from 'react';

function LoadingSpinner(props) {
  const { message } = props;

  return (
    <div
      style={{
        minWidth: '100px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div className="preloader-wrapper active">
        <div className="spinner-layer spinner-blue-only">
          <div className="circle-clipper left">
            <div className="circle" />
          </div>
          <div className="gap-patch">
            <div className="circle" />
          </div>
          <div className="circle-clipper right">
            <div className="circle" />
          </div>
        </div>
      </div>
      <div className="x-mask-message">{message}</div>
    </div>
  );
}

export default LoadingSpinner;
