import React, { useEffect, useRef } from 'react';
import useSpySerpApp from './useSpySerpApp';
import LoadingSpinner from './LoadingSpinner';

function SerpCheckerWidget() {
  const { status } = useSpySerpApp();
  const ref = useRef(null);

  useEffect(() => {
    if (status === 'ready') {
      const { Ext, SpySerpPanel, app } = window;
      window.SpySERPSE = 1;
      app.util._splashDestroy();

      if (!Ext.Viewport) {
        Ext.viewport.Viewport.setup({
          autoRender: false,
        });
      }

      if (!Ext.getApplication())
        Ext.app.Application.instance = new SpySerpPanel.$application();

      if (app.helpers.socket && !app.helpers.socket.connected) {
        app.helpers.socket.connect();
      }

      Ext.Viewport.mask({
        xtype: 'app-fullmask',
        msg: 'Connecting&hellip;',
        style: 'background:#fcfdfe',
      });

      Ext.floatRoot = undefined;
      Ext.Viewport.render(ref.current);

      Ext.Msg.findFloatParent();

      delete Ext.cache['se-holder'];
      delete Ext.cache['ext-global-floatWrap'];

      //TODO: найти где устанавливается в фреймворке и убрать там
      document.body.style.overflowY = 'auto';
      document.body.parentElement.classList.remove('x-fixed-viewport');

      if (!Ext.Viewport.down('app\\.serpexplorer')) {
        Ext.create('SpySerpPanel.view.app.SerpExplorer', {
          fullscreen: true,
        });
      }

      Ext.Viewport.show(true);

      const textField = Ext.Viewport.down(
        'pages\\.serp_explorer\\.views\\.form'
      )?.down('textfield');

      if (textField) {
        textField.addCls('hide-autofill-icon');
        textField.focus();
      }
    }

    return () => {
      if (status === 'ready') {
        const { Ext, SpySerpPanel, app } = global;

        app.helpers.socket.disconnect();

        Ext.getApplication().destroy();

        if (Ext.floatRoot) {
          Ext.floatRoot.destroy();
          Ext.floatRoot = null;
        }

        SpySerpPanel.controller.AppWide.$sidebars = {};
        SpySerpPanel.controller.AppWide.$$sidebar = null;
        app.vars.mainMenu = null;

        Ext.data.StoreManager.items.forEach((store) => store.clearListeners());

        window.SpySERPSE = 0;
      }
    };
  }, [status]);

  let message = 'Loading ...';
  if (status === 'initializing') {
    message = 'Initializing ...';
  }

  return (
    <div
      ref={ref}
      id="se-holder"
      style={{
        height: '477px',
        display: 'flex',
        marginTop: '16px',
      }}
    >
      <div id="splash">
        <div id="splash-content">
          <LoadingSpinner message={message} />
          <div id="status" />
        </div>
      </div>
    </div>
  );
}

export default SerpCheckerWidget;
